/* eslint-disable no-undef */
import guid from "helpers/guid";
import { CURRENCY } from "utils/constants";

const consentGranted = async () => new Promise((resolve) => {
    const checkCookieConsentApi = () => {
        if (window.CookieConsentApi && window.CookieConsentApi.getUserPreferences) {
            resolve(window.CookieConsentApi.getUserPreferences().accept_type === "all");
        } else {
            setTimeout(checkCookieConsentApi, 200);
        }
    };

    checkCookieConsentApi();
});

const eventId = () => `event_${guid()}`;

const getTotalPrice = (items) => Number(items.reduce((acc, item) => acc + item.quantity * item.price, 0).toFixed(2));

export const customerInfo = (customer) => {
    const { id: userId, firstName, lastName, email } = customer;
    return {
        userId, firstName, lastName, email,
    };
};

const trackEvent = (event, data) => {
    if (consentGranted()) {
        // console.log("track:", event, {
        //     event,
        //     event_id: eventId(),
        //     ...data,
        // });
        dataLayer.push({
            event,
            event_id: eventId(),
            ...data,
        });
    }
};

const trackEcomEvent = (event, ecommerce) => {
    if (consentGranted()) {
        dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        trackEvent(event, {
            ecommerce: {
                ...ecommerce,
                currency: CURRENCY,
            },
        });
    }
};

const mapItems = (products, { item_list_name, item_list_id, coupon } = {}) => products.map((product, index) => {
    // product.node comes from shopify cart, product is from pms
    // eslint-disable-next-line no-param-reassign
    product = product?.node ?? product;

    let itemMetrics = product?.item_metrics;
    if (itemMetrics === undefined) {
        // get item metrics from product?.customAttributes
        const customAttributes = product?.customAttributes;
        if (customAttributes) {
            itemMetrics = {
                item_category: customAttributes?.find((attr) => attr.key === "_item_category")?.value,
                item_category2: customAttributes?.find((attr) => attr.key === "_item_category2")?.value,
                item_category3: customAttributes?.find((attr) => attr.key === "_item_category3")?.value,
                item_category4: customAttributes?.find((attr) => attr.key === "_item_category4")?.value,
                item_category5: customAttributes?.find((attr) => attr.key === "_item_category5")?.value,
            };
        }
    }

    const variant = product?.currentVariant?.node ?? product?.variant ?? product?.variants?.edges?.[0]?.node;
    let fabricOption = variant?.selectedOptions?.find((option) => option?.name?.toLowerCase() === "fabric");
    const colorOption = variant?.selectedOptions?.find((option) => option?.name?.toLowerCase() === "color");

    if (fabricOption?.value === undefined) {
        if (product?.title?.toLowerCase().includes("supreme")) {
            fabricOption = { value: "Supreme" };
        } else if (product?.title?.toLowerCase().includes("proguard")) {
            fabricOption = { value: "ProGuard" };
        } else if (product?.title?.toLowerCase().includes("5 layers")) {
            fabricOption = { value: "5 Layers" };
        } else if (product?.title?.toLowerCase().includes("elite")) {
            fabricOption = { value: "Elite" };
        }
    }

    const item = {
        item_id: variant?.id,
        item_name: product?.title,
        affiliation: "Seal Skin Covers",
        currency: CURRENCY,
        index: product?.index ?? index,
        item_brand: "Seal Skin Covers",
        item_category: itemMetrics?.item_category,
        item_category2: itemMetrics?.item_category2,
        item_category3: itemMetrics?.item_category3,
        item_category4: itemMetrics?.item_category4,
        item_category5: itemMetrics?.item_category5,
        item_variant: [fabricOption?.value, colorOption?.value].filter(Boolean).join(" / ") ?? variant?.title,
        price: variant?.price?.amount || variant?.price,
        quantity: product.quantity ?? 1,
        ...(item_list_name ? { item_list_name } : {}),
        ...(item_list_id ? { item_list_id } : {}),
        ...(coupon ? { coupon } : {}),
    };
    if (product?.discounts?.length) {
        let itemDiscount = 0;
        const coupons = [];

        product.discounts.forEach((discount) => {
            itemDiscount += discount.amount;
            coupons.push(discount.title);
        });
        item.discount = itemDiscount;
        item.coupon = coupons.join(",");
    }
    return item;
});

export const trackAddToCart = (products) => {
    const items = mapItems([].concat(products));
    trackEcomEvent("add_to_cart", {
        items,
        value: getTotalPrice(items),
    });
};

export const trackRemoveFromCart = (products) => {
    const items = mapItems([].concat(products));
    trackEcomEvent("remove_from_cart", {
        items,
        value: getTotalPrice(items),
    });
};

export const trackQuantityChange = (products, quantity) => {
    if (quantity < 0) trackRemoveFromCart(products);
    if (quantity > 0) trackAddToCart(products);
};

export const trackViewItem = (product) => {
    trackEcomEvent("view_item", { items: mapItems([product]) });
};

export const trackSliderClick = (product) => {
    trackEcomEvent("slider_click", { items: mapItems([product]) });
};

export const trackBeginCheckout = (cart) => {
    const ecommerce = {
        value: cart.totalPrice,
        items: mapItems(cart.items, { coupon: cart?.discountCode }),
    };

    if (cart.discountCode) ecommerce.coupon = cart.discountCode;

    trackEcomEvent("begin_checkout", ecommerce);
};

export const trackLogIn = (customer) => trackEvent("login", { customer: customerInfo(customer) });

export const trackSignUp = (customer) => trackEvent("sign_up", { customer: customerInfo(customer) });

export const trackViewCart = (cart) => {
    const items = mapItems(cart.items, { coupon: cart.discountCode });
    trackEcomEvent("view_cart", {
        items,
        value: getTotalPrice(items),
        coupon: cart.discountCode,
    });
};

export const trackViewCollection = (products, item_list_name, item_list_id) => {
    const items = mapItems(products, { item_list_name, item_list_id });
    trackEcomEvent("view_item_list", {
        items, item_list_name, item_list_id, 
    });
};

export const trackSelectItem = (product, item_list_name, item_list_id) => {
    const items = mapItems([product], { item_list_name, item_list_id });
    trackEcomEvent("select_item", {
        items, item_list_name, item_list_id, 
    });
};
